import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { MdArrowBackIosNew } from "react-icons/md";
import { MdArrowForwardIos } from "react-icons/md";

function HeroSlider() {
  const slider = React.useRef(null);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
      },
      {
        breakpoint: 600,
      },
      {
        breakpoint: 480,
      },
    ],
  };

  return (
    <div className="slider-container">
      <Slider ref={slider} {...settings}>
        {/* Slide 1 */}
        <div className="revolution-slider">
          <div className="slide-info">
            <div className="tp-caption">
              <div class="hexagon">
                <div class="sl-small-car-oil"></div>
              </div>
            </div>
            <div className="tp-caption">
              <h2>
                <a
                  target="_top"
                  href="#promotion"
                  title="MAKE YOUR CAR LAST LONGER"
                >
                  MAKE YOUR CAR LAST LONGER
                </a>
              </h2>
            </div>
            <div>
              <p class="description">
                Grand opening promotion available. Receive 10% off any service.
                Valid only for October 18, 2021 to December 31, 2021.
              </p>
            </div>
            <div class="align-center">
              <a
                className="more simple"
                href="#promotion"
                title="VIEW PROMOTION"
              >
                <span>VIEW PROMOTION</span>
              </a>
            </div>
          </div>
          <img
            class="slider-bg"
            src="images/slider/image_01.jpg"
            alt="slidebg1"
          />
        </div>
        {/* Slide 2 */}
        <div className="revolution-slider">
          <div className="slide-info">
            <div className="tp-caption">
              <div class="hexagon">
                <div class="sl-small-car-oil"></div>
              </div>
            </div>
            <div className="tp-caption">
              <h2>
                <a
                  target="_top"
                  href="/services"
                  title="MAKE YOUR CAR LAST LONGER"
                >
                  FIND THE SERVICES THAT MATCH YOUR NEEDS
                </a>
              </h2>
            </div>
            <div>
              <p class="description">
                Take advantage of our lowest prices for all of your needs at
                this one stop auto care.
              </p>
            </div>
            <div class="align-center">
              <a className="more simple" href="/services" title="VIEW SERVICES">
                <span>VIEW SERVICES</span>
              </a>
            </div>
          </div>
          <img src="images/slider/image_02.jpg" alt="slidebg2" />
        </div>
        {/* Slide 3 */}
        <div className="revolution-slider">
          <div className="slide-info">
            <div className="tp-caption">
              <div class="hexagon">
                <div class="sl-small-car-oil"></div>
              </div>
            </div>
            <div className="tp-caption">
              <h2>
                <a
                  target="_top"
                  href="#promotion"
                  title="MAKE YOUR CAR LAST LONGER"
                >
                  EXPERT TECHNICIANS, COMPETITIVE PRICES
                </a>
              </h2>
            </div>
            <div>
              <p class="description">
                We are committed to earning your trust by providing the
                expertise and value you expect.
              </p>
            </div>
            <div class="align-center">
              <a className="more simple" href="/about" title="READ MORE">
                <span>READ MORE</span>
              </a>
            </div>
          </div>
          <img src="images/slider/image_03.jpg" alt="slidebg3" />
        </div>
      </Slider>
      <div className="slider-arrow">
        <button
          className="arrow-btn prev tparrows preview1"
          onClick={() => slider?.current?.slickPrev()}
        >
          <MdArrowBackIosNew />
        </button>
        <button
          className="arrow-btn next tparrows preview1"
          onClick={() => slider?.current?.slickNext()}
        >
          <MdArrowForwardIos />
        </button>
      </div>
    </div>
  );
}

export default HeroSlider;

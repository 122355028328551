import React from "react";

function Footer() {
  return (
    <div>
      <div class="row dark-gray footer-row full-width padding-top-30 padding-bottom-50">
        <div class="row padding-bottom-30">
          <div class="column column-1-3">
            <ul class="contact-details-list">
              <li class="sl-small-location-map">
                <p>
                  North York, ON M3M1K4
                  <br />
                  1500 Wilson Ave
                </p>
              </li>
            </ul>
          </div>
          <div class="column column-1-3">
            <ul class="contact-details-list">
              <li class="">
                <p></p>
              </li>
            </ul>
          </div>
          <div class="column column-1-3">
            <ul class="contact-details-list">
              <li class="sl-small-phone-circle">
                <p>
                  Feel Free to Call Us Now
                  <br />
                  (416) 241 6317
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div class="row row-4-4 top-border page-padding-top">
          <div class="column column-1-3">
            <h6 class="box-header">ABOUT US</h6>
            <ul class="list simple margin-top-20">
              <li>North York, ON M3M1K4</li>
              <li>1500 Wilson Ave</li>
              <li>
                <span>Mobile:</span>
                <a href="tel:416-241-6317">(416) 241 6317</a>
              </li>
              <li>
                <span>Assistance:</span>
                <a href="tel:416-241-6317">(416) 241 6317</a>
              </li>
              <li>
                <span>E-mail:</span>
                <a href="mailto:wilsonmufflerinc@gmail.com">
                  wilsonmufflerinc@gmail.com
                </a>
              </li>
            </ul>
            <ul class="social-icons gray margin-top-26">
              <li>
                <a
                  target="_blank"
                  href="https://facebook.com/wilsonmuffler18"
                  class="social-facebook"
                  title="facebook"
                ></a>
              </li>
              <li>
                <a
                  target="_blank"
                  title="instagram"
                  href="https://www.instagram.com/wilsonmuffler"
                  class="social-instagram"
                ></a>
              </li>
              {/* <li>
                <a
                  title=""
                  href="https://1.envato.market/quanticalabs"
                  class="social-instagram"
                ></a>
              </li>
              <li>
                <a
                  title=""
                  href="https://1.envato.market/quanticalabs"
                  class="social-yelp"
                ></a>
              </li> */}
            </ul>
          </div>
          <div class="column column-1-3">
            <h6 class="box-header">OUR SERVICES</h6>
            <ul class="list margin-top-20">
              <li class="template-bullet">Engine Diagnostics</li>
              <li class="template-bullet">Lube, Oil and Filters</li>
              <li class="template-bullet">Belts and Hoses</li>
              <li class="template-bullet">Air Conditioning</li>
              <li class="template-bullet">Brake</li>
              <li class="template-bullet">Tire and Wheel Services</li>
              <li class="template-bullet">Custom Exhaust System</li>
              <li class="template-bullet">
                Complete Front End, Shocks, Struts {"&"}
                Suspension
              </li>
              <li class="template-bullet">Electrical</li>
              <li class="template-bullet">Other Car Services</li>
            </ul>
          </div>
          {/* <div class="column column-1-4">
            <h6 class="box-header">POPULAR TAGS</h6>
            <ul class="taxonomies margin-top-30">
              <li>
                <a href="/service_brake_repair" title="BRAKES">
                  BRAKES
                </a>
              </li>
              <li>
                <a href="/service_engine_diagnostics" title="DIAGNOSTICS">
                  DIAGNOSTICS
                </a>
              </li>
              <li>
                <a href="/service_engine_diagnostics" title="ENGINE">
                  ENGINE
                </a>
              </li>
              <li>
                <a href="/service_lube_oil_filters" title="FILTERS">
                  FILTERS
                </a>
              </li>
              <li>
                <a href="/service_air_conditioning" title="HEATING">
                  HEATING
                </a>
              </li>
              <li>
                <a href="/service_custom_exhaust" title="MUFFLER">
                  MUFFLER
                </a>
              </li>
              <li>
                <a href="/service_lube_oil_filters" title="OILS">
                  OILS
                </a>
              </li>
              <li>
                <a href="/service_tire_wheel" title="STEERING">
                  STEERING
                </a>
              </li>
              <li>
                <a href="/service_tire_wheel" title="SUSPENSION">
                  SUSPENSION
                </a>
              </li>
              <li>
                <a href="/service_tire_wheel" title="TIRES">
                  TIRES
                </a>
              </li>
              <li>
                <a href="/service_engine_diagnostics" title="TRANSMISSION">
                  TRANSMISSION
                </a>
              </li>
              <li>
                <a href="/service_custom_exhaust" title="EXHAUST">
                  CUSTOM EXHAUST
                </a>
              </li>
            </ul>
          </div> */}
          <div class="column column-1-3">
            <h6 class="box-header">HOURS</h6>
            <ul class="list simple margin-top-20">
              <li>
                <span>Monday:</span>8:00am - 6:00pm
              </li>
              <li>
                <span>Tuesday:</span>8:00am - 6:00pm
              </li>
              <li>
                <span>Wednesday:</span>8:00am - 6:00pm
              </li>
              <li>
                <span>Thursday:</span>8:00am - 6:00pm
              </li>
              <li>
                <span>Friday:</span>8:00am - 6:00pm
              </li>
              <li>
                <span>Saturday:</span>8:00am - 3:00pm
              </li>
              <li>
                <span>Sunday:</span>Closed
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="row align-center padding-top-bottom-30">
        <span class="copyright">
          © Copyright 2021{" "}
          <span>
            Wilson Muffler
          </span>
        </span>
      </div>
    </div>
  );
}

export default Footer;

import React from "react";

function Header() {
  return (
    <div>
      <div class="header-top-bar-container clearfix">
        <div class="header-top-bar">
          <ul class="contact-details clearfix">
            <li class="template-phone">
              <a href="tel:416-241-6317">(416) 241 6317</a>
            </li>
            <li class="template-mail">
              <a href="mailto:wilsonmufflerinc@gmail.com">
                wilsonmufflerinc@gmail.com
              </a>
            </li>
            <li class="template-clock">
              Mon - Fri: 8:00am - 6:00pm, Sat: 8:00am - 3:00pm
            </li>
          </ul>
          <ul class="social-icons">
            <li>
              <a
                target="_blank"
                href="https://facebook.com/wilsonmuffler18"
                class="social-facebook"
                title="facebook"
              ></a>
            </li>
            <li>
              <a
                target="_blank"
                title="instagram"
                href="https://www.instagram.com/wilsonmuffler"
                class="social-instagram"
              ></a>
            </li>

            {/* <li>
              <a
                title=""
                href="https://1.envato.market/quanticalabs"
                class="social-instagram"
              ></a>
            </li>
            <li>
              <a
                title=""
                href="https://1.envato.market/quanticalabs"
                class="social-yelp"
              ></a>
            </li> */}
          </ul>
        </div>
        <a href="#" class="header-toggle template-arrow-up"></a>
      </div>
      <div class="header-container">
        <div class="vertical-align-table column-1-1">
          <div class="header clearfix">
            <div class="logo vertical-align-cell">
              <h1>
                <a href="/" title="CarService">
                  <img src="images/logos/SpeedMufflerLogo.png" />
                </a>
              </h1>
            </div>
            <a href="#" class="mobile-menu-switch vertical-align-cell">
              <span class="line"></span>
              <span class="line"></span>
              <span class="line"></span>
            </a>
            <div class="menu-container clearfix vertical-align-cell">
              <nav>
                <ul class="sf-menu">
                  <li class="selected">
                    <a href="/" title="Home">
                      Home
                    </a>
                  </li>
                  <li>
                    <a href="/services" title="Services">
                      Services
                    </a>
                    {/* <ul>
                      <li>
                        <a
                          href="/service_engine_diagnostics"
                          title="Engine Diagnostics"
                        >
                          Engine Diagnostics
                        </a>
                      </li>
                      <li>
                        <a
                          href="/service_lube_oil_filters"
                          title="Lube, Oil and Filters"
                        >
                          Lube, Oil and Filters
                        </a>
                      </li>
                      <li>
                        <a
                          href="/service_air_conditioning"
                          title="Air Conditioning"
                        >
                          Heating and Cooling Systems
                        </a>
                      </li>
                      <li>
                        <a href="/service_brake_repair" title="Brake Repair">
                          Brake Repair
                        </a>
                      </li>
                      <li>
                        <a
                          href="/service_tire_wheel"
                          title="Tire and Wheel Services"
                        >
                          Wheel Alignment
                        </a>
                      </li>
                      <li>
                        <a
                          href="/service_custom_exhaust"
                          title="Custom Exhaust System"
                        >
                          Custom Exhaust System
                        </a>
                      </li>
                    </ul> */}
                  </li>
                  <li>
                    <a href="/about" title="About">
                      About
                    </a>
                  </li>
                  <li>
                    <a href="/appointment" title="Appointment">
                      Appointment
                    </a>
                  </li>
                  <li class="left-flyout">
                    <a href="/contact" title="Contact">
                      Contact
                    </a>
                  </li>
                </ul>
              </nav>
              <div class="mobile-menu-container">
                <div class="mobile-menu-divider"></div>
                <nav>
                  <ul class="mobile-menu collapsible-mobile-submenus">
                    <li class="selected">
                      <a href="/" title="Home">
                        Home
                      </a>
                      <a href="#" class="template-arrow-menu"></a>
                    </li>
                    <li>
                      <a href="/services" title="Services">
                        Services
                      </a>
                      {/* <a href="#" class="template-arrow-menu"></a>
                      <ul>
                        <li>
                          <a
                            href="/service_engine_diagnostics"
                            title="Engine Diagnostics"
                          >
                            Engine Diagnostics
                          </a>
                        </li>
                        <li>
                          <a
                            href="/service_lube_oil_filters"
                            title="Lube, Oil and Filters"
                          >
                            Lube, Oil and Filters
                          </a>
                        </li>
                        <li>
                          <a
                            href="/service_belts_hoses"
                            title="Belts and Hoses"
                          >
                            Belts and Hoses
                          </a>
                        </li>
                        <li>
                          <a
                            href="/service_air_conditioning"
                            title="Air Conditioning"
                          >
                            Air Conditioning
                          </a>
                        </li>
                        <li>
                          <a href="/service_brake_repair" title="Brake Repair">
                            Brake Repair
                          </a>
                        </li>
                        <li>
                          <a
                            href="/service_tire_wheel"
                            title="Tire and Wheel Services"
                          >
                            Tire and Wheel Services
                          </a>
                        </li>
                        <li>
                          <a
                            href="/service_custom_exhaust"
                            title="Custom Exhaust System"
                          >
                            Custom Exhaust System
                          </a>
                        </li>
                      </ul> */}
                    </li>
                    <li>
                      <a href="/about" title="About">
                        About
                      </a>
                      {/* <a href="#" class="template-arrow-menu"></a> */}
                    </li>
                    <li>
                      <a href="/appointment" title="Appointment">
                        Appointment
                      </a>
                    </li>
                    <li class="left-flyout">
                      <a href="/contact" title="Contact">
                        Contact
                      </a>
                      {/* <a href="#" class="template-arrow-menu"></a> */}
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;

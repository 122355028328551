import React from "react";

function Status404() {
  return (
    <div>
      <div class="site-container">
        <div class="theme-page padding-bottom-66">
          <div class="clearfix page-404 top-border">
            <div class="row margin-top-70">
              <div class="column column-1-1">
                <h2>OOPS.</h2>
                <h1>404</h1>
                <p class="description align-center">
                  We're sorry but something went wrong. Return to the{" "}
                  <a href="/" title="GO TO HOME">
                    homepage
                  </a>{" "}
                  or use the search below.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Status404;

import React from "react";
import BrandSlider from "../components/BrandSlider";
import HeroSlider from "../components/HeroSlider";
import ReviewSlider from "../components/ReviewSlider";

function Home() {
  const style = {
    display: "none",
  };

  return (
    <main>
      <div class="site-container">
        <div class="revolution-slider-container">
          <HeroSlider />
        </div>
        {/* <!--/--> */}
        <div class="theme-page">
          <div class="clearfix">
            <div class="row gray full-width">
              <div class="announcement clearfix">
                <ul class="columns no-width">
                  <li class="column column-2-3">
                    <div class="vertical-align">
                      <div class="vertical-align-cell">
                        <h3>MAKE AN APPOINTMENT NOW WITH OUR ONLINE FORM</h3>
                      </div>
                    </div>
                  </li>
                  <li class="column column-1-3">
                    <div class="vertical-align">
                      <div class="vertical-align-cell">
                        <a
                          class="more"
                          href="/appointment"
                          title="MAKE AN APPOINTMENT"
                        >
                          <span>MAKE AN APPOINTMENT</span>
                        </a>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div class="row page-margin-top-section">
              <div class="row">
                <h2 class="box-header">WHY CHOOSE US?</h2>
                <p class="description align-center">
                  We are one of the leading auto repair shops serving customers
                  in the Greater Toronto Area.
                  <br />
                  All mechanic services are performed by highly qualified
                  mechanics.
                </p>
                <div class="row page-margin-top">
                  <div class="column column-1-3">
                    <ul class="features-list big">
                      <li>
                        <div class="hexagon">
                          <div class="sl-small-user-chat"></div>
                        </div>
                        <h4 class="box-header page-margin-top">
                          EVERY JOB IS PERSONAL
                        </h4>
                        <p>
                          If you want the quality you would expect from the
                          dealership, but with a more personal and friendly
                          atmosphere, you have found it.
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div class="column column-1-3">
                    <ul class="features-list big">
                      <li>
                        <div class="hexagon">
                          <div class="sl-small-wrench-screwdriver"></div>
                        </div>
                        <h4 class="box-header page-margin-top">
                          BEST MATERIALS
                        </h4>
                        <p>
                          We have invested in all the latest specialist tools
                          and diagnostic software that is specifically tailored
                          for the software in your vehicle.
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div class="column column-1-3">
                    <ul class="features-list big">
                      <li>
                        <div class="hexagon">
                          <div class="sl-small-truck-tow"></div>
                        </div>
                        <h4 class="box-header page-margin-top">
                          PROFESSIONAL STANDARDS
                        </h4>
                        <p>
                          Our auto repair shop is capable of servicing a variety
                          of models. We only do the work that is needed to fix
                          your problem.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="align-center margin-top-67 padding-bottom-20">
                  <a class="more" href="/about" title="READ MORE">
                    <span>READ MORE</span>
                  </a>
                </div>
              </div>
            </div>
            <div class="row full-width gray flex-box page-margin-top-section">
              <div class="column column-1-2 background-1">
                <a
                  class="flex-hide"
                  href="service_engine_diagnostics.html"
                  title="Tiling and Painting"
                >
                  <img src="images/samples/960x680/image_01.jpg" alt="" />
                </a>
              </div>
              <div class="column column-1-2 padding-bottom-66">
                <div class="row padding-left-right-100">
                  <h2 class="box-header page-margin-top-section">
                    VEHICLES SERVICED
                  </h2>
                  <p class="description align-center">
                    We provide top notch maintenance service for all types of
                    vehicles.
                    <br />
                    We are certified to service and repair all makes:
                  </p>
                  <div class="row margin-top-30">
                    <div class="column column-1-3">
                      <ul class="list">
                        <li class="template-bullet">General Motors</li>
                        {/* <li class="template-bullet">
                          Import and Domestic Vehicles
                        </li>
                        <li class="template-bullet">European Vehicles</li>
                        <li class="template-bullet">Lincoln</li>
                        <li class="template-bullet">Mazda</li> */}
                      </ul>
                    </div>
                    <div class="column column-1-3">
                      <ul class="list">
                        <li class="template-bullet">
                          Import and Domestic Vehicles
                        </li>
                        {/* <li class="template-bullet">Mercury</li>
                        <li class="template-bullet">Mitsubishi</li>
                        <li class="template-bullet">Nissan</li>
                        <li class="template-bullet">Renault</li> */}
                      </ul>
                    </div>
                    <div class="column column-1-3">
                      <ul class="list">
                        <li class="template-bullet">European Vehicles</li>
                        {/* <li class="template-bullet">Pontiac Porsche</li>
                        <li class="template-bullet">Rover</li>
                        <li class="template-bullet">Saab</li>
                        <li class="template-bullet">Saleen</li> */}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row full-width page-padding-top-section">
              <div class="row">
                <h2 class="box-header">AUTO REPAIR SERVICES</h2>
                <p class="description align-center">
                  We offer a full range of garage services to vehicle owners
                  located in the GTA area.
                  <br />
                  Our professinals know how to handle a wide range of car
                  services.
                </p>
                <ul class="services-list clearfix page-margin-top">
                  <li>
                    {/* <a
                      href="/service_custom_exhaust"
                      title="Tire and Wheel Services"
                    > */}
                    <img
                      src="images/samples/390x260/exhaust390x260.jpg"
                      alt=""
                    />
                    {/* </a> */}
                    <h4 class="box-header">
                      {/* <a
                        href="/service_custom_exhaust"
                        title="Tire and Wheel Services"
                      > */}
                      CUSTOM EXHAUST SYSTEMS
                      {/* <span class="template-arrow-menu"></span> */}
                      {/* </a> */}
                    </h4>
                  </li>
                  <li>
                    {/* <a
                      href="/service_tire_wheel"
                      title="Tire and Wheel Services"
                    > */}
                    <img src="images/samples/390x260/image_06.jpg" alt="" />
                    {/* </a> */}
                    <h4 class="box-header">
                      {/* <a
                        href="/service_tire_wheel"
                        title="Tire and Wheel Services"
                      > */}
                      WHEEL ALIGNMENT
                      {/* <span class="template-arrow-menu"></span> */}
                      {/* </a> */}
                    </h4>
                  </li>
                  <li>
                    {/* <a
                      href="/service_lube_oil_filters"
                      title="Lube, Oil and Filters"
                    > */}
                    <img src="images/samples/390x260/image_02.jpg" alt="" />
                    {/* </a> */}
                    <h4 class="box-header">
                      {/* <a
                        href="/service_lube_oil_filters"
                        title="Lube, Oil and Filters"
                      > */}
                      LUBE, OIL AND FILTERS
                      {/* <span class="template-arrow-menu"></span> */}
                      {/* </a> */}
                    </h4>
                  </li>
                </ul>
                <div class="align-center margin-top-40 padding-bottom-87">
                  <a class="more" href="/services" title="VIEW ALL SERVICES">
                    <span>VIEW ALL SERVICES</span>
                  </a>
                </div>
              </div>
            </div>
            {/* <div class="row full-width padding-top-70 padding-bottom-66 parallax parallax-1">
              <ReviewSlider />
            </div> */}
            <div
              class="row full-width padding-top-54 align-center"
              id="promotion"
            >
              <h2 class="box-header padding-bottom-20">PROMOTION</h2>
              <img class="promo-card" src="images/promotions/promo1.png" />
            </div>
            <div class="row full-width top-border page-margin-top-section padding-bottom-50">
              <div class="row">
                <div class="our-clients-list-container page-margin-top">
                  <BrandSlider />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Home;

import React, { useState } from "react";
import Map from "../components/Map";
import emailjs from "emailjs-com";

function Contact() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });
  const [message, setMessage] = useState(false);
  const [error, setError] = useState(false);

  function handleSubmission(e) {
    e.preventDefault();
    setMessage(false);
    setError(false);

    emailjs
      .sendForm(
        "speed_muffler",
        "speed_contact",
        e.target,
        "user_ysxnLJCoufkp7P1WvhQWX"
      )
      .then(
        (result) => {
          setMessage(true);
          setFormData({
            name: "",
            email: "",
            phone: "",
            message: "",
          });
          console.log(result.text);
        },
        (error) => {
          setError(true);
          console.log(error.text);
        }
      );
  }

  return (
    <div>
      <div class="site-container">
        <div class="theme-page padding-bottom-66">
          <div class="clearfix">
            <div class="row full-width">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5603.029747435038!2d-79.50297259475698!3d43.71874673129477!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b3105c80ba847%3A0xd4dcb1b6cb980e91!2s1500%20Wilson%20Ave%2C%20North%20York%2C%20ON%20M3M%201K4!5e0!3m2!1sen!2sca!4v1633557265833!5m2!1sen!2sca"
                width="100%"
                height="450"
                style={{ border: "0" }}
                allowfullscreen=""
                loading="lazy"
              ></iframe>
            </div>
            <div class="row page-margin-top">
              <div class="column column-1-2">
                <ul class="features-list">
                  <li>
                    <h5>CALL US NOW AT</h5>
                    <div class="icon sl-small-phone-circle"></div>
                    <p>
                      Mobile: (416) 241 6317
                      <br />
                      Assistance: (416) 241 6317
                      <br />
                      Weekdays: (416) 241 6317
                    </p>
                  </li>
                </ul>
              </div>
              <div class="column column-1-2">
                <ul class="features-list">
                  <li>
                    <h5>WILSON MUFFLER</h5>
                    <div class="icon sl-small-location-map"></div>
                    <p>
                      North York, ON M3M1K4
                      <br />
                      1500 Wilson Ave
                      <br />
                      <a
                        target="_blank"
                        href="https://goo.gl/maps/xBECLcqgjW2XM8EH9"
                      >
                        Display on Map
                      </a>
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div class="row page-margin-top">
              <form
                class="contact-form"
                id="contact-form"
                method="post"
                action="/contact"
                onSubmit={handleSubmission}
              >
                <div class="row">
                  <fieldset class="column column-1-2">
                    <input
                      required
                      class="text-input"
                      name="user_name"
                      type="text"
                      value={formData.name}
                      placeholder="Your Name *"
                      onChange={(e) =>
                        setFormData({ ...formData, name: e.target.value })
                      }
                    />
                    <input
                      class="text-input"
                      name="user_email"
                      type="text"
                      value={formData.email}
                      placeholder="Your Email *"
                      onChange={(e) =>
                        setFormData({ ...formData, email: e.target.value })
                      }
                    />
                    <input
                      class="text-input"
                      name="contact_number"
                      type="text"
                      value={formData.phone}
                      placeholder="Your Phone"
                      onChange={(e) =>
                        setFormData({ ...formData, phone: e.target.value })
                      }
                    />
                  </fieldset>
                  <fieldset class="column column-1-2">
                    <textarea
                      name="message"
                      placeholder="Message *"
                      value={formData.message}
                      onChange={(e) =>
                        setFormData({ ...formData, message: e.target.value })
                      }
                    ></textarea>
                  </fieldset>
                </div>
                <div class="row margin-top-30">
                  <div class="column column-1-2">
                    <p>We will contact you within one business day.</p>
                  </div>
                  <div class="column column-1-2 align-right">
                    <input type="hidden" name="action" value="contact_form" />
                    <div class="row margin-top-20 padding-bottom-20">
                      <button
                        class="more submit-contact-form"
                        href="#"
                        title="SEND MESSAGE"
                        type="submit"
                      >
                        <span>SEND MESSAGE</span>
                      </button>
                    </div>
                  </div>
                </div>
              </form>
              {message && (
                <div class="contact-message">
                  <span>Thank you for your message!</span>
                </div>
              )}
              {error && (
                <div class="contact-message message-error">
                  <span>
                    There is an issue with your request. Please contact us
                    directly through email or call the phone number for
                    assistance.
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;

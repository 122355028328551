import { BrowserRouter, Route, Switch } from "react-router-dom";

import logo from "./logo.svg";
import "./App.css";
import Home from "../src/pages/Home";
import About from "../src/pages/About";
import Appointment from "./pages/Appointment";
import Contact from "./pages/Contact";
import Status404 from "../src/pages/Status404";
import Services from "./pages/Services";
import ServiceEngine from "./pages/ServiceItems/ServiceEngine";
import ServiceLube from "./pages/ServiceItems/ServiceLube";
import ServiceBelt from "./pages/ServiceItems/ServiceBelt";
import ServiceHeating from "./pages/ServiceItems/ServiceHeating";

import Footer from "../src/components/Footer";
import Header from "../src/components/Header";
import ServiceBrake from "./pages/ServiceItems/ServiceBrake";
import ServiceTire from "./pages/ServiceItems/ServiceTire";
import ServiceExhaust from "./pages/ServiceItems/ServiceExhaust";
import ServicesAlt from "./pages/ServicesAlt";

function App() {
  return (
    <BrowserRouter>
      <Header />
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route path="/about">
          <About />
        </Route>
        <Route path="/appointment">
          <Appointment />
        </Route>
        <Route path="/contact">
          <Contact />
        </Route>
        <Route path="/services">
          <ServicesAlt />
        </Route>
        {/* <Route path="/service_engine_diagnostics">
          <ServiceEngine />
        </Route>
        <Route path="/service_lube_oil_filters">
          <ServiceLube />
        </Route>
        <Route path="/service_air_conditioning">
          <ServiceHeating />
        </Route>
        <Route path="/service_brake_repair">
          <ServiceBrake />
        </Route>
        <Route path="/service_tire_wheel">
          <ServiceTire />
        </Route>
        <Route path="/service_belts_hoses">
          <ServiceBelt />
        </Route>
        <Route path="/service_custom_exhaust">
          <ServiceExhaust />
        </Route> */}
        <Route>
          <Status404 />
        </Route>
      </Switch>
      <Footer />
    </BrowserRouter>
  );
}

export default App;

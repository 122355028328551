import React from "react";

function ServicesAlt() {
  return (
    <div>
      <div class="theme-page padding-bottom-70">
        <div class="row gray full-width page-header vertical-align-table">
          <div class="row full-width padding-top-bottom-50 vertical-align-cell">
            <div class="row">
              <div class="page-header-left">
                <h1>OUR SERVICES</h1>
              </div>
              <div class="page-header-right">
                <div class="bread-crumb-container">
                  <label>You Are Here:</label>
                  <ul class="bread-crumb">
                    <li>
                      <a title="Home" href="home.html">
                        HOME
                      </a>
                    </li>
                    <li class="separator">&#47;</li>
                    <li>OUR SERVICES</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="clearfix">
          <div class="row padding-top-70">
            <ul class="services-list services-icons services-items-border row clearfix">
              <li>
                <div class="hexagon small">
                  <span class="service-icon sl-small-engine-belt"></span>
                </div>
                <div class="service-content">
                  <h4 class="box-header">CUSTOM EXHAUST SYSTEM</h4>
                  <p>
                    Our specialty is custom exhaust systems, including the
                    muffler, exhaust pipe, catalytic converter, flex pipe, and
                    complete exhaust custom bending. We know that people want
                    their vehicles to look good and sound great. We take the
                    extra time to make sure the systems look right and even.
                  </p>
                </div>
              </li>
              <li>
                <div class="hexagon small">
                  <span class="service-icon sl-small-car-wheel"></span>
                </div>
                <div class="service-content">
                  <h4 class="box-header">WHEEL ALIGNMENT</h4>
                  <p>
                    Our mechanics will perform a test and drive and a thorough
                    examination of the tires and suspension components. Our
                    mechanics will be able to recommend the right alignment type
                    for your vehicle, either front-end alignment, thrust
                    alignment, or four-wheel alignment.
                  </p>
                </div>
              </li>
            </ul>
            <ul class="services-list services-icons services-items-border row clearfix margin-top-30">
              <li>
                <div class="hexagon small">
                  <span class="service-icon sl-small-oil-gauge"></span>
                </div>
                <div class="service-content">
                  <h4 class="box-header">LUBE, OIL AND FILTERS</h4>
                  <p>
                    An oil change and filter replacement are some of the many
                    preventative maintenance services that help promote maximum
                    vehicle performance while extending the life of your
                    vehicle. Our mechanics will inspect the oil filter during
                    each oil change, and replace the oil filter if needed. For
                    the transmission oil, we use the dealer oil only.
                  </p>
                </div>
              </li>
              <li>
                <div class="hexagon small">
                  <span class="service-icon sl-small-air-conditioning"></span>
                </div>
                <div class="service-content">
                  <h4 class="box-header">HEATING AND COOLING SYSTEM</h4>
                  <p>
                    Other than providing comfort for your driving experience,
                    your vehicle's heating system is responsible for cooling
                    your vehicle's engine on hot days, and the air condition
                    systems play a vital role during the colder months. Our
                    mechanics can provide system inspection, preventative
                    maintenance, and repairs.
                  </p>
                </div>
              </li>
            </ul>
            <ul class="services-list services-icons services-items-border row clearfix margin-top-30">
              <li>
                <div class="hexagon small">
                  <span class="service-icon sl-small-signal-warning"></span>
                </div>
                <div class="service-content">
                  <h4 class="box-header">BRAKE REPAIR</h4>
                  <p>
                    The brake system is one of your vehicle's most important
                    safety features. Our mechanics will perform a thorough
                    inspection of all the different parts of a hydraulic brake
                    system including Brake Pedal, Brake Master Cylinder, Brake
                    Lines, Rotors/Drums, Wheel Cylinders, and Brake Pads.
                  </p>
                </div>
              </li>
              <li>
                <div class="hexagon small">
                  <span class="service-icon sl-small-car-check"></span>
                </div>
                <div class="service-content">
                  <h4 class="box-header">ENGINE DIAGNOSTICS</h4>
                  <p>
                    Our mechanics are trained in operating high-tech engine
                    diagnostics performance machines that will help in detecting
                    what is going on with your engine. In addition to expert
                    diagnostic testing, we provide all the services you would
                    need to get your vehicle ready to go back on the road.
                  </p>
                </div>
              </li>
            </ul>
            <ul class="services-list services-icons services-items-border row clearfix margin-top-30">
              <li>
                <div class="hexagon small">
                  <span class="service-icon sl-small-steering-wheel"></span>
                </div>
                <div class="service-content">
                  <h4 class="box-header">
                    COMPLETE FRONT-END, SHOCKS, STRUTS {"&"} SUSPENSION
                  </h4>
                  <p>
                    Our mechanic will run a diagnosis on the suspension system
                    and make the necessary adjustments to ensure the handling
                    and steering feels right. The front end will be inspected
                    and provide recommendations to replace any worn parts.
                  </p>
                </div>
              </li>
              <li>
                <div class="hexagon small">
                  <span class="service-icon sl-small-electric-wrench"></span>
                </div>
                <div class="service-content">
                  <h4 class="box-header">ELECTRICAL</h4>

                  <p>
                    Our mechanics use diagnostic equipment and scan tools to
                    isolate auto-electrical faults in your vehicle and repair
                    them, solving potential issues with essential vehicle
                    systems like ABS brakes, power steering, central locking and
                    body electrics.{" "}
                  </p>
                  <br />
                  <br />
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ServicesAlt;

import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function BrandSlider() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div>
      <Slider {...settings}>
        <div className="logo-pic">
          <img src="images/logos/firestone-logo.png" />
        </div>
        <div className="logo-pic">
          <img src="images/logos/Goodyear-logo.png" />
        </div>
        <div className="logo-pic">
          <img src="images/logos/honda-logo.jpg" />
        </div>
        <div className="logo-pic">
          <img src="images/logos/Liqui-Moly-Vector-Logo.png" />
        </div>
        <div className="logo-pic">
          <img src="images/logos/Mopar-Logo.png" />
        </div>
        <div className="logo-pic">
          <img src="images/logos/quakerstatelogo.png" />
        </div>
        <div className="logo-pic">
          <img src="images/logos/stp-logo.jpg" />
        </div>
        <div className="logo-pic">
          <img src="images/logos/Toyota-Logo.png" />
        </div>
      </Slider>
    </div>
  );
}

export default BrandSlider;

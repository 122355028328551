import React from "react";
import ReviewSlider from "../components/ReviewSlider";

function About() {
  return (
    <div>
      <div class="site-container">
        <div class="theme-page">
          <div class="row gray full-width page-header vertical-align-table">
            <div class="row full-width padding-top-bottom-50 vertical-align-cell">
              <div class="row">
                <div class="page-header-left">
                  <h1>ABOUT US</h1>
                </div>
                <div class="page-header-right">
                  <div class="bread-crumb-container">
                    <label>YOU ARE HERE:</label>
                    <ul class="bread-crumb">
                      <li>
                        <a title="HOME" href="index.html">
                          HOME
                        </a>
                      </li>
                      <li class="separator">&#47;</li>
                      <li>ABOUT US</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="clearfix">
            <div class="row margin-top-70 padding-bottom-50">
              <div class="column column-1-2">
                <p class="description margin-top-0">
                  We offer a full range of garage services to vehicle owners
                  located in Greater Toronto area. All mechanic services are
                  performed by highly qualified mechanics. We can handle any car
                  problem.
                </p>
                <p class="margin-top-10">
                  Our professionals know how to handle a wide range of car
                  services. Whether you drive a passenger car or medium sized
                  truck or SUV, our mechanics strive to ensure that your vehicle
                  will be performing at its best before leaving our car shop.
                </p>
                <h4 class="box-header margin-top-26">WHY CHOOSE US</h4>
                <ul class="list margin-top-30">
                  <li class="template-bullet">
                    We are specialized on custom and exhaust system, flex pipe,
                    catalytic converter with unbeatable prices
                  </li>
                  <li class="template-bullet">
                    We make auto repair and maintenance more convenient for you
                  </li>
                  <li class="template-bullet">
                    We are a friendly, helpful and professional group of people
                  </li>
                  <li class="template-bullet">
                    Our professionals know how to handle a wide range of car
                    services
                  </li>
                  <li class="template-bullet">
                    We get the job done right — the first time
                  </li>
                  <li class="template-bullet">
                    Same day service for most repairs and maintenance
                  </li>
                </ul>
                <div class="page-margin-top">
                  <a class="more" href="/services" title="OUR SERVICES">
                    <span>OUR SERVICES</span>
                  </a>
                </div>
              </div>
              <div class="column column-1-2">
                <a
                  href="images/samples/870x580/exhaust870x580.jpg"
                  class="prettyPhoto re-preload"
                  title="Exhaust"
                >
                  <img
                    src="images/samples/570x380/exhaust570x380.jpg"
                    alt="img"
                  />
                </a>
                <div class="row margin-top-30">
                  <div class="column column-1-2">
                    <a
                      href="images/samples/870x580/image_07.jpg"
                      class="prettyPhoto re-preload"
                      title="Wheel Services"
                    >
                      <img
                        src="images/samples/570x380/image_07.jpg"
                        alt="img"
                      />
                    </a>
                  </div>
                  <div class="column column-1-2">
                    <a
                      href="images/samples/870x580/image_02.jpg"
                      class="prettyPhoto re-preload"
                      title="Oil Change"
                    >
                      <img
                        src="images/samples/570x380/image_02.jpg"
                        alt="img"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>

            {/* <div class="row full-width padding-top-70 padding-bottom-66 parallax parallax-2">
              <ReviewSlider />
            </div>
            <div class="row page-margin-top-section padding-bottom-66">
              <div class="row">
                <h2 class="box-header">COMPANY OVERVIEW</h2>
                <p class="description align-center">
                  We can help you with everything from an oil change to an
                  engine change.
                  <br />
                  We can handle any problem on both foreign and domestic
                  vehicles.
                </p>
              </div>
              <div class="row page-margin-top-section">
                <div class="column column-1-3">
                  <ul class="features-list">
                    <li>
                      <h5>CAR AUDIO SERVICE</h5>
                      <div class="icon sl-small-car-audio"></div>
                      <p>
                        Services consequat luctus at justo auctor metus est
                        donec. Tempus terminal morbi nulla donec elite maecenas
                        modern forte elementum vehicula.
                      </p>
                    </li>
                    <li>
                      <h5>CAR A/C RECHARGE</h5>
                      <div class="icon sl-small-air-conditioning"></div>
                      <p>
                        Services consequat luctus at justo auctor metus est
                        donec. Tempus terminal morbi nulla donec elite maecenas
                        modern forte elementum vehicula.
                      </p>
                    </li>
                  </ul>
                </div>
                <div class="column column-1-3">
                  <ul class="features-list">
                    <li>
                      <h5>FREE OIL CHANGE</h5>
                      <div class="icon sl-small-car-oil"></div>
                      <p>
                        Services consequat luctus at justo auctor metus est
                        donec. Tempus terminal morbi nulla donec elite maecenas
                        modern forte elementum vehicula.
                      </p>
                    </li>
                    <li>
                      <h5>PARKING SENSORS CALIBRATION</h5>
                      <div class="icon sl-small-parking-sensor"></div>
                      <p>
                        Services consequat luctus at justo auctor metus est
                        donec. Tempus terminal morbi nulla donec elite maecenas
                        modern forte elementum vehicula.
                      </p>
                    </li>
                  </ul>
                </div>
                <div class="column column-1-3">
                  <ul class="features-list">
                    <li>
                      <h5>ENGINE DIAGNOSTICS</h5>
                      <div class="icon sl-small-signal-warning"></div>
                      <p>
                        Services consequat luctus at justo auctor metus est
                        donec. Tempus terminal morbi nulla donec elite maecenas
                        modern forte elementum vehicula.
                      </p>
                    </li>
                    <li>
                      <h5>CAR BATTERY REPAIRS</h5>
                      <div class="icon sl-small-car-battery"></div>
                      <p>
                        Services consequat luctus at justo auctor metus est
                        donec. Tempus terminal morbi nulla donec elite maecenas
                        modern forte elementum vehicula.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div> */}
            <div class="row gray full-width padding-top-54 padding-bottom-61 align-center">
              <h3>
                <span class="button-label">BOOK AN APPOINTMENT ONLINE</span>{" "}
                <a class="more" href="/appointment" title="PURCHASE TEMPLATE">
                  <span>MAKE APPOINTMENT</span>
                </a>
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;

import React from "react";
import GoogleMapReact from "google-map-react";
import LocationPin from "./LocationPin";

function Map() {
  const location = {
    address: "1500 Wilson Ave, North York, ON M3M 1K4",
    lat: 43.72225,
    lng: -79.50723,
  };

  return (
    <div className="map">
      <div className="google-map">
        <GoogleMapReact
          bootstrapURLKeys={{ key: "" }}
          defaultCenter={location}
          defaultZoom={17}
        >
          <LocationPin
            lat={location.lat}
            lng={location.lng}
            text={location.address}
          />
        </GoogleMapReact>
      </div>
    </div>
  );
}

export default Map;
